@import "../../styles/common.scss";

.login-wrapper {
    .form-signin input[type=email],
    .form-signin input[type=password],
    .form-signin input[type=text],
    .form-signin button {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
        height: 55px;
        position: relative;
        width: 100%;
        z-index: 1;
    }

    .form-signin .form-control:focus {
        border-color: rgb(104, 145, 162);
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgb(104, 145, 162);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgb(104, 145, 162);
        outline: 0;
    }

    .loader {
        border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

    .login-container {
        height: 100vh;
        margin: auto 0 auto auto;
        padding: auto;
        width: 100%;
    }
    //huy.quang: disable default eye icon on ie < 10
    input::-ms-reveal,
    input::-ms-clear
    {
        display: none;
    }
    .login-container {
        padding: 100px 0 0 0;
    }
    .title-panel {
        max-width: 550px;
        margin: auto;
        .title {
            color: #fff;
        background-color: rgb(0,166,129);
        border-color: rgb(0,166,129);
        padding: 10px 15px;
        border-bottom: 1px solid transparent;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        text-align: left;
        font-weight: normal;
        margin-bottom: 0;
        font-size: 16px;
        text-transform: none;
        }
    }
    .form_login {
        // background: $colormain;
        border:none;
        margin: auto;
        height: 375px;
        max-width: 550px;
        padding: 20px 20px;
        padding-top: 30px;
        border: 1px solid rgb(0,166,129);
        border-radius: 4px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        .error-msg {
            color:$common-error;
            margin-top:10px;
            text-align: center
        }
        .notify-msg {
            color:$common-notify;
            margin-top:10px;
            text-align: center
        }
        .form-group {
            input[type=submit], input[type=button] {
                &:focus, &:hover {
                    background: $common-btn-deny;
                    border: 1px solid $common-btn-deny;
                    filter: brightness(120%);
                }
            }
            margin-bottom:20px;
            position: relative;
            &.separator {
                background: $common-text-color;
                border: none;
                height: 1px;
                padding: 0 50px;
            }
            .form-control{
                background: $common-text-color;
                border: 1px solid rgba(0, 0, 0, 0.8);
                border-radius: 3px;
                -webkit-border-radius: 3px;
                box-sizing: border-box;
                color: rgba(0,0,0,0.8);
                height: 40px;
                margin-bottom: 0;     
                &:focus, &:hover{
                    border: 3px solid $input-focus;
                }
            }
            &.icon-true {
                .form-control {
                    background: $common-text-color;
                    padding-left: 50px;
                    width: 100%;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-right: 0;
                }
                .icon {
                    left: 0;
                    position: absolute;top: 0;
                    background-color: #eee;
                    border-radius: 4px;
                }
            }
            .error {
                display: none;
                text-align: right
            } 
        }
        .form-pass {
            text-decoration: none;
            color: rgb(77,77,79);
            font-size: 85%;
        }
        .login{
            .btn{
                background-color: rgb(0,166,129);
                color: #fff;
                display: block;
                font-size: 16px;
                height: 40px;
                width: 80px;
                padding: 6px 12px;
                border: 1px solid transparent;
                font-weight: normal;
                &.dn {
                    background: $common-btn-confirm;
                }
                &.dk {
                    background: $common-btn-confirm;
                }
            }
        }
    } 
    .login-error {
        width: 100%;
        margin-bottom: 20px;
        background-color: #f2dede;
        border-color: #eed3d7;
        text-align: left;
        padding: 15px;
        border: 1px solid transparent;
        border-radius: 4px;
        .login-error-message {
            font-size: 20px;
        }
    }
    
    /*=============END FOOTER==========*/
    
    /*=============MENU MOBILE==========*/
    /*=============END MENU MOBILE==========*/
    
    /*RESPONSIVE*/
    @media screen and (min-device-width: 1280px) and (max-device-width: 2400px) {
        .login-container {
            padding: 30px 0 0 0;
        }
    }
    @media (max-width: 1600px){
        .form_login{
            height: 375px;
            max-width: 550px;
            .form-group {
                margin-bottom: 20px;
            }
        }
    }
    @media (max-width: 1479px){
        .form_login{
            height: 375px;
            max-width: 550px;
            .form-group {
                margin-bottom: 20px;
            }
        }
    }
    @media (max-width: 1380px){
        .form_login{
            height: 375px;
            max-width: 550px;
            .form-group {
                margin-bottom: 20px;
            }
        }
    }
    @media (max-width: 1280px){
        .login-container {
            padding: 30px 0 0 0;
        }
        .form_login{
            height: 375px;
            max-width: 550px;
            .form-group {
                margin-bottom: 20px;
            }
        }
    }
    @media (max-width: 960px){
        .form_login {
            margin: auto
        }
        .login-container {
            padding: 40px 30px 0 30px;
        }
    }
    
    @media (max-width: 740px){
        .form_login {
            margin: auto
        }
        .login-container {
            padding: 40px 30px 0 30px;
        }
    }
    
    @media (max-width: 590px){
        .form_login{
            margin: auto
        }
        .login-container {
            padding: 40px 30px 0 30px;
        }
    }
    @media (max-width: 420px){
        .form_login{
            margin: auto
        }
        .login-container {
            padding: 40px 30px 0 30px;
        }
    }
    @media (max-width: 360px){
        .forgot-password {
            display: none;
        }
        .form_login{
            margin: auto
        }
        .login-container {
            padding: 40px 30px 0 30px;
        }
    }
}
